import React, { useRef, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'
import Alert from '../Alert/Alert'

import styles from './MailingAddressForm.module.css'

const MailingAddressForm = () => {
  const form = useRef()
  const [showForm, setShowForm] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [formData, setFormData] = useState({})
  const [formIsValid, setFormIsValid] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    setTimeout(() => {
      setShowAlert(false)
    }, 3000)
  }, [])

  const renderAlter = (alterType, address) => {
    return (
      <Alert
        alterType={alterType}
        address={address}
        showAlert={showAlert}
      />
    )
  }

  const handleValidation = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value})

    // Name
    if ((Object?.keys(formData)?.includes('name') && formData?.name?.trim()?.length !== 0) || (name === 'name' && value?.trim()?.length !== 0)) {
      delete errors?.name
      setErrors(errors)
    } else {
      errors.name = 'Your name is required.'
    }

    // Email
    if (!Object?.keys(formData)?.includes('email')) {
      errors.email = 'Your email is required.'
    } else if (name === 'email' || formData?.email) {
      const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
      if (!emailRegex.test(formData?.email?.toLowerCase())) {
        errors.email = 'Your email is invalid.'
      } else {
        delete errors?.email
        setErrors(errors)
      }
    }

    // Address
    if ((Object?.keys(formData)?.includes('address') && formData?.address?.trim()?.length !== 0) || (name === 'address' && value?.trim()?.length !== 0)) {
      delete errors?.address
    } else {
      errors.address = 'Your address is required.'
    }

    setErrors(errors)
    if (Object?.keys(errors)?.length === 0) {
      setFormIsValid(true)
    } else {
      setFormIsValid(false)
    }
  }

  const sendEmail = (e) => {
    if (formIsValid) {
      e.preventDefault()
      setShowAlert(true)

      emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
        .then((result) => {
          renderAlter('success', result.text)
          setShowForm(false)
          console.log(result.text)
        }, (error) => {
          renderAlter('error', error.text)
          console.log(error.text)
        })
    }
  }

  return (
    <div className={styles.formContainer}>
      {showForm
        ? (
          <div className={styles.formWrapper}>
            <p className={styles.note}>
              {/* Please provide your address for a physical invitation */}
              For a physical invitation:
            </p>
            <form
              ref={form}
              className={styles.form}
              onSubmit={e => sendEmail(e)}
              onChange={e => handleValidation(e)}
            >
              <div className={styles.formWrap}>
                <div className={styles.questionWrap}>
                  <label>Name</label>
                  <input
                    className={styles.input}
                    type='text'
                    name='name'
                  />
                  {errors?.name && <span className={styles.error}>{errors?.name}</span>}
                </div>

                <div className={styles.questionWrap}>
                  <label className={styles.label}>Email</label>
                  <input
                    className={styles.input}
                    type='email'
                    name='email'
                  />
                  {errors?.email && <span className={styles.error}>{errors?.email}</span>}
                </div>

                <div className={styles.questionWrap}>
                  <label className={styles.label}>Mailing Address</label>
                  <input
                    className={styles.input}
                    type='text'
                    name='address'
                  />
                  {errors?.address && <span className={styles.error}>{errors?.address}</span>}
                </div>
              </div>

              <input
                className={`${styles.submitButton} ${formIsValid ? '' : styles.disabled}`}
                type='submit'
                value='Submit'
                disabled={!formIsValid}
              />
            </form>
          </div>)
        : (
          <p>
            Thanks for providing the mailing address!
          </p>
        )}
    </div>
  )
}

export default MailingAddressForm
