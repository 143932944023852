import React from 'react'
import saveTheDate from '../../images/save-the-date.png'
import styles from './SaveTheDate.module.css'

const SaveTheDate = () => {
  return (
    <div className={styles.image}>
      <img
        src={saveTheDate}
        alt='save the date'
      />
    </div>
  )
}

export default SaveTheDate
