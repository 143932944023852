import './App.css'
import { Route, Routes } from 'react-router-dom'

import Home from './pages/Home'
import SaveTheDate from './pages/SaveTheDate'

function App() {
  return (
    <div className='App' style={{ overflow: 'hidden' }}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/save-the-date' element={<SaveTheDate />} />
      </Routes>
    </div>
  );
}

export default App;
