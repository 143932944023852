import React from 'react'

import ea from '../../images/ea.png'
import styles from './Header.module.css'

const Head = () => {
  return (
    <nav className={styles.nav}>
      <div className={styles.navContainer}>
        <div className={styles.navHeader}>
          <a className={styles.navLogo} href='#invitation'>
            <img src={ea} alt='elaine & albert' />
          </a>
        </div>
      </div>        
    </nav>
  )
}

export default Head
