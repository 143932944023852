import React from 'react'

import SaveTheDate from '../components/SaveTheDate/SaveTheDate'
import MailingAddressForm from '../components/Form/MailingAddressForm'
import Footer from '../components/Footer/Footer'

const SaveTheDatePage = () => {
  return (
    <>
      <SaveTheDate />
      <MailingAddressForm />
      <Footer />
    </>
  )
}

export default SaveTheDatePage
