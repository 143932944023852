import React from 'react'

import styles from './Alert.module.css'

const Alert = ({
  alterType,
  message,
  showAlert
}) => {
  return (
    <>
      {showAlert
        ? (
          <div className={`${styles.alert} ${styles[alterType]}`}>
            <p>{message}</p>
          </div>)
        : <></>}
    </>
  )
}

export default Alert
