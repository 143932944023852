import React from 'react'

import styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a href='mailto: hello@eaforever.com'>hello@eaforever.com</a>
    </footer>
  )
}

export default Footer