import React from 'react'

import Head from '../components/Header/Head'
import EA from '../images/EA script.svg'
import Footer from '../components/Footer/Footer'

import styles from '../components/Invitation/Invitation.module.css'

const Home = () => {
  return (
    <div>
      <Head />
      <div className={styles.image}>
        <img src={EA} alt='elaine & albert' />
      </div>
      <Footer />
    </div>
  )
}

export default Home
